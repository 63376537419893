<template>
  <div class="card">
    <div class="card-header"><strong>Posted by Rob Astudillo</strong></div>
    <div class="card-body">
      <div class="author-image">
        <img
          src="/assets/images/author/rob.jpg"
          alt="Image"
          class="rounded-circle" />
      </div>
      <p>
        <i>
          <a href="https://www.linkedin.com/in/robastudillo/">Rob</a>
          is the CEO of eFlexervices. For more than 10 years, he has been
          building powerful business partnerships with US companies by building
          high-performing offshore teams that help optimize operations and build
          cost saving solutions to enable growth, scale, and optimization
        </i>
      </p>
      <p>
        <i>
          In 2009 he founded “The Hotel Inventory” (now AQQIRE), an online
          marketing tool that allows hotel industry professionals to market
          their properties for sale without getting lost in a sea of other real
          estate industries. Due to its success, The Hotel Inventory was
          acquired by Pracrea Inc. 2014, and was then rebranded to AQQIRE in
          2017.
        </i>
      </p>
      <p>
        <i>
          Rob is passionate about investing in people and culture in building
          high-performing outsourced teams that drive results and deliver
          high-quality services for partners and customers of companies.
        </i>
      </p>
    </div>
  </div>
  <!-- Post Single - Author End -->
</template>

<script>
  export default {
    data() {
      return {}
    },
  }
</script>
